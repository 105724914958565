import { gql } from '@apollo/client';

import { fragments as mediaFragments } from '../../../../components/Media';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment CheeriosVideoAll on CheeriosVideo {
      ...ContentfulSysId
      contentfulDescription
      alternateTextDescription
      posterImage {
        ...AssetAll
      }
      thumbnailImage {
        ...AssetAll
      }
      videoSourcesCollection(limit: 10) {
        items {
          ...AssetAll
        }
      }
      captionCollection(limit: 10) {
        items {
          ...AssetAll
        }
      }
      isMuted
      isAutoplay
      withControls
    }
    ${mediaFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query CheeriosVideoQuery($preview: Boolean!, $locale: String!, $id: String!) {
    cheeriosVideo(preview: $preview, locale: $locale, id: $id) {
      ...CheeriosVideoAll
    }
  }
  ${fragments.all}
`;
