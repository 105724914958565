import { gql } from '@apollo/client';

import { buttonFragment } from '../../../../components/Button/query';
import { fragments as imageFragments } from '../../../../components/Image/query';
import { fragments as videoFragments } from '../../../../components/Video/query';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import { fragments as cheeriosMultiVideoFragments } from '../CheeriosMultiVideoBlock/query';
import { fragments as cheeriosVideoFragments } from '../CheeriosVideo/query';

export const fragments = {
  all: gql`
    fragment CheeriosLayerAll on CheeriosLayer {
      ...ContentfulSysId
      title
      cssProperties
      mobileCssProperties
      desktopCssProperties
      content {
        ...CheeriosContentAll
      }
      mobileContent @include(if: $isMobile) {
        ...CheeriosContentAll
      }
      mobileSectionFramesCollection(limit: 10) @include(if: $isMobile) {
        items {
          ...CheeriosFrameAll
        }
      }
      desktopSectionFramesCollection(limit: 10) @skip(if: $isMobile) {
        items {
          ...CheeriosFrameAll
        }
      }
    }
    fragment CheeriosContentAll on Entry {
      ...ContentfulSysId
      ... on Image {
        ...ImageAll
      }
      ... on Video {
        ...VideoAll
      }
      ... on CheeriosVideo {
        ...CheeriosVideoAll
      }
      ... on CheeriosText {
        ...ContentfulSysId
        text
      }
      ... on CheeriosScrollBy {
        ...ContentfulSysId
        cheeriosText {
          ... on CheeriosText {
            ...ContentfulSysId
            text
          }
        }
        scrollAmount
      }
      ... on Button {
        ...ButtonAll
      }
      ... on CheeriosMultiVideoBlock {
        ...CheeriosMultiVideoBlockAll
      }
    }
    fragment CheeriosFrameAll on CheeriosFrame {
      ...ContentfulSysId
      left
      top
      scale
      opacity
      width
      height
      rotate
      transitionTimingFunction
      transitionDuration
    }
    ${videoFragments.all}
    ${imageFragments.all}
    ${buttonFragment}
    ${cheeriosMultiVideoFragments.all}
    ${cheeriosVideoFragments.all}
    ${contentfulSysIdFragment}
  `,
};
