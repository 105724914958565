import { gql } from '@apollo/client';

import { buttonFragment } from '../../../../components/Button/query';
import { fragments as imageFragments } from '../../../../components/Image/query';
import { fragments as mosaicFragments } from '../../../../components/Mosaic/query';
import { fragments as videoFragments } from '../../../../components/Video/query';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import { fragments as cheeriosMultiVideoFragments } from '../CheeriosMultiVideoBlock/query';

const fragments = {
  all: gql`
    fragment CheeriosStaticBlockAll on CheeriosStaticBlock {
      ...ContentfulSysId
      title
      cssProperties
      mobileCssProperties
      desktopCssProperties
      content {
        ... on Entry {
          ...ContentfulSysId
        }
        ... on Image {
          ...ImageAll
        }
        ... on Video {
          ...VideoAll
        }
        ... on CheeriosText {
          ...ContentfulSysId
          text
        }
        ... on Button {
          ...ButtonAll
        }
        ... on CheeriosMultiVideoBlock {
          ...CheeriosMultiVideoBlockAll
        }
        ... on Mosaic {
          ...MosaicAll
        }
      }
    }
    ${mosaicFragments.all}
    ${videoFragments.all}
    ${imageFragments.all}
    ${buttonFragment}
    ${cheeriosMultiVideoFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query CheeriosStaticBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    cheeriosStaticBlock(id: $id, locale: $locale, preview: $preview) {
      ...CheeriosStaticBlockAll
    }
  }
  ${fragments.all}
`;
