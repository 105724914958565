import { cx } from '@emotion/css';
import type { BaseComponentProps } from '@snapchat/snap-design-system-marketing';
import { dataSetToAttributes } from '@snapchat/snap-design-system-marketing';
import unescape from 'lodash-es/unescape';
import type { FC } from 'react';
import { useRef } from 'react';

import { cheeriosScrollByCss } from './style';

interface Props extends BaseComponentProps {
  text: string;
  scrollAmount: string;
}

export const CheeriosScrollBy: FC<Props> = props => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const text = unescape(props.text);

  const onClick = () => {
    const amount = Number(props.scrollAmount.replace(/\D/g, ''));
    let multiplier = 1;

    if (props.scrollAmount.endsWith('vh')) {
      multiplier = window.innerHeight / 100;
    } else if (props.scrollAmount.endsWith('vw')) {
      multiplier = window.innerWidth / 100;
    } else if (props.scrollAmount.endsWith('%')) {
      multiplier = (buttonRef.current?.offsetParent?.clientHeight ?? 100) / 100;
    }

    // TODO: Support `em`, `rem` etc.
    window.scrollBy({ top: Math.round(amount * multiplier) });
  };

  return (
    <button
      ref={buttonRef}
      style={props.style}
      className={cx(cheeriosScrollByCss, props.className)}
      {...dataSetToAttributes(props.dataset)}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
