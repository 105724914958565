import camelCase from 'lodash-es/camelCase';
import mapKeys from 'lodash-es/mapKeys';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import { useMobileLayout } from '../../../../hooks/useLayout';
import type { Items } from '../../../../types/Items';
import type { CheeriosSectionProps } from '../CheeriosBlock/types';
import { CheeriosLayer } from '../CheeriosLayer/CheeriosLayer';
import { CheeriosPamphletContext } from '../CheeriosPamphlet/CheeriosPamphletContext';

interface Props {
  sections: Items<CheeriosSectionProps>;
}

/**
 * Renderer for the layers that extracts start/end frames for the current section.
 *
 * This is an all in one component because layers persist between sections.
 */
export const CheeriosSections: FC<Props> = props => {
  const [currentSection, setCurrentSection] = useState<number>(0);
  const pamphletContext = useContext(CheeriosPamphletContext);
  const isMobile = useMobileLayout();

  useEffect(() => {
    pamphletContext.addSectionChangeListener(setCurrentSection);

    return () => {
      pamphletContext.removeSectionChangeListener(setCurrentSection);
    };
  }, [pamphletContext, setCurrentSection]);

  return (
    <>
      {props.sections.items.flatMap((section, index) => {
        return section.layersCollection.items.map(layer => {
          const frames = isMobile
            ? layer.mobileSectionFramesCollection?.items
            : layer.desktopSectionFramesCollection?.items;

          // Pick mobile or desktop content; default to desktop content if mobile content is null.
          const content = (isMobile ? layer.mobileContent : layer.content) ?? layer.content;

          // Show nothing when transitioning between mobile/desktop but data hasn't loaded yet.
          if (!frames || !content) {
            return null;
          }

          // Do not render layers out of frame.
          if (currentSection < index || currentSection >= index + frames.length) {
            return null;
          }

          const startFrame = frames[currentSection - index]!;
          const endFrame = frames[currentSection - index + 1] ?? startFrame;

          const mergedCssProperties = {
            ...layer.cssProperties,
            ...(isMobile ? layer.mobileCssProperties : layer.desktopCssProperties),
          };
          const cssProperties = mapKeys(mergedCssProperties, (_value, key) => camelCase(key));
          return (
            <CheeriosLayer
              key={layer.sys.id}
              startFrame={startFrame}
              endFrame={endFrame}
              isMobile={isMobile}
              metadata={{
                frameStartIndex: index,
                frameCount: frames.length,
              }}
              content={content}
              title={layer.title}
              cssProperties={cssProperties}
            />
          );
        });
      })}
    </>
  );
};
