import { cx } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { BaseComponentProps } from '@snapchat/snap-design-system-marketing';
import { dataSetToAttributes } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ImageDataProps } from '../../../../components/Image/types';
import type { ContentfulImageProps } from '../../../../components/Media/mediaUtils';
import { objectFitCoverCss } from './styles';

export const cheeriosImageContentfulImageProps: ContentfulImageProps = {
  widthPx: 1400, // Max width we allow images to be loaded at.
  quality: 85, // Slightly higher quality for static images.
};

type Props = Pick<ImageDataProps, 'media'> & BaseComponentProps;

/** Image element using the latest multi-source tech */
export const CheeriosImage: FC<Props> = ({ media, className, style, dataset }) => {
  const { getImageSources } = useContentfulImages();
  const classNames = cx(objectFitCoverCss, className);

  const imageSources = getImageSources(media.url, {
    image: cheeriosImageContentfulImageProps,
  });

  return (
    <img
      alt={media.description}
      src={imageSources?.default}
      srcSet={imageSources?.defaultSrcSet}
      style={style}
      className={classNames}
      {...dataSetToAttributes(dataset)}
    />
  );
};

CheeriosImage.displayName = 'CheeriosImage';
