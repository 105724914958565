import { Partition } from '@snapchat/graphene';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'pixy.com',
  localeFilter: ['en-US', 'fr-FR'],
  trackingSettings: {
    cookieDomain: 'pixy.com',

    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.CHEERIOS },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleAnalyticsId: 'UA-41740027-89',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'cheerios',
        dsn: 'https://e44b73e9933f4f14b6554307773e58e7@sentry.sc-prod.net/169',
      },
    ],
  },
  redirects: {
    hostRedirects: {},
  },
  persistentQueryParams: ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'],
  globalNavProps: {
    backgroundColor: BackgroundColor.White,
    showGlobalLinks: false,
  },
  siteMap: {
    customPaths: {
      // Top level domain, should include cookie-settings in site map
      '/cookie-settings': { isLocalized: true },
    },
  },
  csp: {
    scriptSrc: [
      'https://storage.googleapis.com/spectacles-v2/', // For chat bubble on pixy
    ],
    connectSrc: [
      // shopify integration
      'https://snapchat-spectacles-trial-store.myshopify.com',
      'https://spectacles-eur-en-dev.myshopify.com',
      'https://eur-en.orders.spectacles.com',
      'https://orders.spectacles.com',
    ],
  },
  theme: {
    breakTemplates: [], // No breaks
  },
};
