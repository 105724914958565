import { createContext } from 'react';

type EventListener<T> = (arg: T) => void;

export type ProgressChangeDirection = 'increasing' | 'decreasing';

export type ProgressChangeEvent = {
  progress: number;
  previousProgress: number;
  section: number;
  previousSection: number;
  direction: ProgressChangeDirection;
};

export class CheeriosPamphletEventBus {
  private sectionChangeListeners: Set<EventListener<number>>;
  private currentSection: number;
  private previousSection: number;
  private progressChangeListeners: Set<EventListener<ProgressChangeEvent>>;
  private currentProgress: number;
  private previousProgress: number;
  private direction: ProgressChangeDirection;

  constructor() {
    this.currentSection = 0;
    this.previousSection = 0;
    this.currentProgress = 0;
    this.previousProgress = 0;
    this.sectionChangeListeners = new Set();
    this.progressChangeListeners = new Set();
    this.direction = 'increasing';
  }

  private notifyProgressChangeListener = (listener: EventListener<ProgressChangeEvent>): void => {
    listener({
      progress: this.currentProgress,
      previousProgress: this.previousProgress,
      section: this.currentSection,
      previousSection: this.previousSection,
      direction: this.direction,
    });
  };

  addSectionChangeListener = (listener: EventListener<number>, fireLastEvent = true): void => {
    fireLastEvent && listener(this.currentSection);
    this.sectionChangeListeners.add(listener);
  };

  addProgressChangeListener = (
    listener: EventListener<ProgressChangeEvent>,
    fireLastEvent = true
  ): void => {
    fireLastEvent && this.notifyProgressChangeListener(listener);
    this.progressChangeListeners.add(listener);
  };

  removeSectionChangeListener = (listener: EventListener<number>): void => {
    this.sectionChangeListeners.delete(listener);
  };

  removeProgressChangeListener = (listener: EventListener<ProgressChangeEvent>): void => {
    this.progressChangeListeners.delete(listener);
  };

  onProgressChange = (progress: number, sectionIndex: number): void => {
    if (sectionIndex !== this.currentSection) {
      this.sectionChangeListeners.forEach(listener => listener(sectionIndex));
    }

    this.previousProgress = this.currentProgress;
    this.currentProgress = progress;
    this.previousSection = this.currentSection;
    this.currentSection = sectionIndex;
    // TODO: Wrap in try-catch to avoid crashes if some of the elements do not animate.
    this.progressChangeListeners.forEach(this.notifyProgressChangeListener);

    const previousPosition = this.previousSection + this.previousProgress;
    const currentPosition = this.currentSection + this.currentProgress;
    this.direction = previousPosition > currentPosition ? 'decreasing' : 'increasing';
  };
}

/** Animation section/progress eventbus. */
export const CheeriosPamphletContext = createContext<CheeriosPamphletEventBus>(
  new CheeriosPamphletEventBus()
);
CheeriosPamphletContext.displayName = 'PamphletContext';
