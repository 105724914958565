/* eslint-disable max-len */
import type { BaseComponentProps } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

export type GenericSvgIconProps = BaseComponentProps & React.SVGProps<SVGSVGElement>;

//TODO: Move to sdsm if this is something that can be used in other places
export const PlayButtonIcon: FC<GenericSvgIconProps> = ({ ...props }) => {
  return (
    <svg
      width="97"
      height="109"
      viewBox="0 0 97 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M89.5 48.4378C94.1667 51.1321 94.1667 57.8679 89.5 60.5622L15.25 103.43C10.5833 106.125 4.74999 102.757 4.75 97.3682L4.75 11.6318C4.75 6.24314 10.5833 2.87526 15.25 5.56956L89.5 48.4378Z"
        stroke="white"
        strokeWidth="8"
      />
    </svg>
  );
};
