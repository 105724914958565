import { cx } from '@emotion/css';
import type { BaseComponentProps } from '@snapchat/snap-design-system-marketing';
import type { CSSProperties, FC } from 'react';
import { useState } from 'react';

import { MultiVideoBlock } from '../../../../components/MultiVideoBlock';
import { logEvent, SubscribedEventType } from '../../../../helpers/logging';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { cheeriosFrameCss } from '../CheeriosLayer/styles';
import type { CheeriosTextDataProps } from '../CheeriosText/types';
import {
  gradientCss,
  mainVideoTextCss,
  secondaryVideoTextCss,
  togglePanelCss,
  videoTextCss,
} from './styles';

export interface CheeriosMultiVideoBlockProps extends BaseComponentProps {
  style: CSSProperties;
  multiVideoBlock: ContentfulSysProps;
  mainTextCollection?: Items<CheeriosTextDataProps>;
  secondaryTextCollection?: Items<CheeriosTextDataProps>;
  togglePanelLabel?: string;
}

export interface CheeriosMultiVideoBlockDataProps extends ContentfulSysProps {
  __typename: 'CheeriosMultiVideoBlock';
  multiVideoBlock: ContentfulSysProps;
  mainTextCollection?: Items<CheeriosTextDataProps>;
  secondaryTextCollection?: Items<CheeriosTextDataProps>;
  togglePanelLabel?: string;
}

export const CheeriosMultiVideoBlock: FC<CheeriosMultiVideoBlockProps> = ({
  style,
  multiVideoBlock,
  mainTextCollection,
  secondaryTextCollection,
  togglePanelLabel,
}) => {
  const [index, setIndex] = useState(0);

  const handleVideoChange = (index: number, isChecked?: boolean) => {
    setIndex(index);

    logEvent({
      subscribedEventType: SubscribedEventType.USER_INTERACTION,
      eventAction: 'Select',
      eventCategory: 'CheeriosMultiVideoBlock',
      eventLabel: `${isChecked ? 'Secondary' : 'Primary'} Video ${index + 1} `,
    });
  };

  return (
    <MultiVideoBlock
      onVideoChange={handleVideoChange}
      style={style}
      id={multiVideoBlock.sys.id}
      togglePanelClassName={togglePanelCss}
      togglePanelLabel={togglePanelLabel}
    >
      <div className={gradientCss} />
      <div className={videoTextCss}>
        <label className={cx(cheeriosFrameCss, mainVideoTextCss)}>
          {mainTextCollection?.items[index]!.text}
        </label>

        <label className={cx(cheeriosFrameCss, secondaryVideoTextCss)}>
          {secondaryTextCollection?.items[index]!.text}
        </label>
      </div>
    </MultiVideoBlock>
  );
};
