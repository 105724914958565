import { css } from '@emotion/css';

import { responsiveFontCss } from '../../../../styles/responsiveFontCss';

export const pamphletSectionContainerHeight = '--pamphlet-section-container-height';

export const pamphletOffsetTopVar = '--pamphlet-offset-top';

export const pamphletSectionProgressVar = '--pamphlet-section-progress';

export const outerContainerCss = css`
  width: 100%;
  position: relative;
  height: var(${pamphletSectionContainerHeight});

  /* Note: We had removed paint and layout "contains" prop because it breaks sticky container in Safari. */

  ${responsiveFontCss}

  /* Font on this page is generally big, but we still want it to look skinny. */
  font-weight: 300;
`;

export const stickyDisplayContainerCss = css`
  position: sticky;
  top: var(${pamphletOffsetTopVar});
  left: 0;
  width: 100%;
  height: calc(100vh - var(${pamphletOffsetTopVar}));
  overflow: hidden;
`;
