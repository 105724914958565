import { css } from '@emotion/css';

export const cheeriosButtonCss = css`
  border-radius: 64px;
  white-space: nowrap;
  align-items: center;
  padding: 0 1.45em;
  text-decoration: none;
  font-size: 0.65em;
  line-height: 2.6em;
  font-weight: 500;
`;

export const buttonTypePrimaryCss = css`
  color: var(--button-primary-fg-color);
  background-color: var(--button-primary-bg-color);
`;

export const buttonTypeSecondaryCss = css`
  color: var(--button-primary-fg-color);
  background-color: var(--button-secondary-bg-color);
`;
