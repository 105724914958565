import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import { fragments as cheeriosLayerFragments } from '../CheeriosLayer/query';

// Test the query at https://app.contentful.com/spaces/svn43w404u4n/environments/latest-dev/apps/app_installations/graphql-playground/graphql
export const query = gql`
  ${cheeriosLayerFragments.all}
  query CheeriosBlockEntryQuery(
    $id: String!
    $preview: Boolean!
    $locale: String!
    $isMobile: Boolean!
  ) {
    cheeriosBlock(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      sectionsCollection(limit: 15) {
        items {
          ...ContentfulSysId
          height
          layersCollection(limit: 10) {
            items {
              ...CheeriosLayerAll
            }
          }
        }
      }
    }
  }
  ${contentfulSysIdFragment}
`;
