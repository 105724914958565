import cloneDeep from 'lodash-es/cloneDeep';

import type { CheeriosFrameProps } from '../CheeriosLayer/types';
import type { CheeriosBlockData } from './types';

/* Merges 2 objects together */
const copyForward = <T extends CheeriosFrameProps>(
  mutableObject: T,
  previousObject: Partial<T>,
  filterKeys?: Set<string>
): void => {
  if (!mutableObject || !previousObject) {
    return;
  }

  for (const [key, previousValue] of Object.entries(previousObject)) {
    if (filterKeys?.has(key)) {
      continue;
    }
    const castKey = key as keyof T;

    if (mutableObject[castKey] === undefined || mutableObject[castKey] === null) {
      mutableObject[castKey] = previousValue;
    }
  }
};

/**
 * Copies forward values from frames between sections.
 *
 * This is a helper method that allows us to specify only the overrides in CMS instead of every
 * single value - every single frame.
 */
export const copyForwardSectionData = (
  data?: CheeriosBlockData,
  filterKeys?: Set<string>
): CheeriosBlockData | null => {
  if (!data) return null;

  const mutableData = cloneDeep(data);

  for (const section of mutableData.cheeriosBlock.sectionsCollection.items) {
    for (const layer of section.layersCollection.items) {
      // Copy forward desktop frames.
      if (layer.desktopSectionFramesCollection) {
        let previousDesktopFrame: Partial<CheeriosFrameProps> = {};

        for (const i in layer.desktopSectionFramesCollection.items) {
          // TODO: Figure out why nested cloneDeep is needed. But without it the original values
          // seem to be modified.
          const desktopFrame = cloneDeep(layer.desktopSectionFramesCollection.items[i]!);
          copyForward(desktopFrame, previousDesktopFrame, filterKeys);
          layer.desktopSectionFramesCollection.items[i] = desktopFrame;
          previousDesktopFrame = desktopFrame;
        }
      }

      // Copy forward mobile frames.
      if (layer.mobileSectionFramesCollection) {
        let previousMobileFrame: Partial<CheeriosFrameProps> = {};

        for (const i in layer.mobileSectionFramesCollection.items) {
          const mobileFrame = cloneDeep(layer.mobileSectionFramesCollection.items[i]!);
          copyForward(mobileFrame, previousMobileFrame, filterKeys);
          layer.mobileSectionFramesCollection.items[i] = mobileFrame;
          previousMobileFrame = mobileFrame;
        }
      }
    }
  }

  return mutableData;
};
