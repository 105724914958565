import { css } from '@emotion/css';

export const cheeriosScrollByCss = css`
  width: fit-content;
  cursor: pointer;
  background-color: unset;
  color: unset;
  font-family: unset;
  font-weight: 300;
`;
