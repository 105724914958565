import { cx } from '@emotion/css';
import type { BaseComponentProps } from '@snapchat/snap-design-system-marketing';
import { dataSetToAttributes } from '@snapchat/snap-design-system-marketing';
import unescape from 'lodash-es/unescape';
import type { FC } from 'react';
import React from 'react';

import { cheeriosTextCss } from './style';

interface Props extends BaseComponentProps {
  text: string;
}

export const CheeriosText: FC<Props> = props => {
  const lines = unescape(props.text)
    .replace(/(\n|\\n|&#13;)/g, '\n')
    .split('\n');

  return (
    <label
      style={props.style}
      className={cx(cheeriosTextCss, props.className)}
      {...dataSetToAttributes(props.dataset)}
    >
      {React.Children.map(lines, (child: string, id: number) => (
        <React.Fragment key={id}>
          {id === lines.length - 1 ? (
            child
          ) : (
            <>
              {child}
              <br />
            </>
          )}
        </React.Fragment>
      ))}
    </label>
  );
};
