import { css } from '@emotion/css';

import { cheeriosAnimationUnitDurationMs } from '../CheeriosBlock/cheeriosConstants';

export const cheeriosFrameCss = css`
  /** Double specific to override SDS-M styles for buttons. */
  && {
    position: absolute;
    transition-timing-function: linear;
    /*
    We presume that the next unit animation will take ~1 rendering frame's worth of time to schedule
    which means it will execute at unitDurationTime + JSEventLoopTime + timeUntilNextAnimationFrame
    but since that's not something that we can program, we roughly double the duration here for a
    a smoother animation effect.
    */
    transition-duration: ${cheeriosAnimationUnitDurationMs * 2}ms;
    transition-property: top, left, opacity, transform;
    will-change: top, left, opacity, transform;
  }
`;
