import { css } from '@emotion/css';

export const objectFitCoverCss = css`
  object-fit: cover;
`;

export const objectFitContainCss = css`
  object-fit: contain;
`;

export const fullScreenCss = css`
  width: 100%;
  height: 100%;
`;

export const videoWrapperCss = css`
  /* We prevent overflow to make the video inside have rounded borders */
  overflow: hidden;
  /* Must match the video objectFit property */
  background-size: cover;
`;

export const playButtonOverlayCss = css`
  background-color: transparent;
  cursor: pointer;
`;

export const playButtonCss = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
