import { css } from '@emotion/css';

import { mediaQuery } from './mediaQueries';

/**
 * A responsive, large font going from 20px to 52px (typical 32px) in size. If you need a different
 * font-size, set this on the parent and change the descendant font size to be using em units (i.e.
 * if you want 16px, set to .5em)
 */
export const responsiveFontCss = css`
  font-size: 20px;
  ${mediaQuery.over320_mobile_small} {
    font-size: 22px;
  }
  ${mediaQuery.over375_mobile_medium} {
    font-size: 24px;
  }
  ${mediaQuery.over425_mobile_large} {
    font-size: 26px;
  }
  ${mediaQuery.over768_desktop_small} {
    font-size: 24px;
  }
  ${mediaQuery.over1024_desktop_medium} {
    font-size: 32px;
  }
  ${mediaQuery.over1440_desktop_large} {
    font-size: 42px;
  }
  ${mediaQuery.over2560_desktop_larger} {
    /* This is disproportianelty small to avoid extra large content. */
    font-size: 52px;
  }
`;
