import type { CSSProperties, FC, PropsWithChildren } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';
import { logEvent, SubscribedEventType } from '../../../../helpers/logging';
import { CheeriosPamphletContext } from './CheeriosPamphletContext';
import {
  outerContainerCss,
  pamphletOffsetTopVar,
  pamphletSectionContainerHeight,
  pamphletSectionProgressVar,
  stickyDisplayContainerCss,
} from './styles';

export interface CheeriosPamphletProps {
  sectionHeights: number[];
  offsetTop: number;
}

/**
 * Component that renders N sections with snapping between them.
 *
 * Scroll-animation container with the sections snapping. Controls section# and progress%
 */
export const CheeriosPamphlet: FC<PropsWithChildren<CheeriosPamphletProps>> = props => {
  // References to dom elements. Note that this is the correct way to capture elements, it's an
  // anti-pattern to use useRef() for this because it does NOT re-render when a reference capture
  // happens.
  // See https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const [stickyDisplayContainer, setStickyDisplayContainer] = useState<HTMLElement>();
  const stickyDisplayContainerCapture = useCallback(
    (node: HTMLElement) => setStickyDisplayContainer(node),
    []
  );
  const [outerContainer, setOuterContainer] = useState<HTMLElement>();
  const outerContainerCapture = useCallback((node: HTMLElement) => setOuterContainer(node), []);
  const [currentSection, setCurrentSection] = useState<number>(0);

  const containerHeightVh = props.sectionHeights.reduce((sum, height) => sum + height, 0);

  const { onProgressChange } = useContext(CheeriosPamphletContext);

  const updateProgress = useCallback(() => {
    if (!stickyDisplayContainer || !outerContainer) {
      return;
    }
    const scrollY = stickyDisplayContainer.offsetTop; // This is based of the container, which is exactly what we want.
    const baseSectionHeight = outerContainer.offsetHeight / containerHeightVh;
    let sectionIndex = 0;
    let sectionStartPx = 0; // First section always starts at 0.

    while (sectionStartPx + baseSectionHeight * props.sectionHeights[sectionIndex]! < scrollY) {
      sectionStartPx += baseSectionHeight * props.sectionHeights[sectionIndex]!;
      sectionIndex++;
    }
    const sectionEndPx = sectionStartPx + baseSectionHeight * props.sectionHeights[sectionIndex]!;
    const currentSectionProgress = (scrollY - sectionStartPx) / (sectionEndPx - sectionStartPx);

    stickyDisplayContainer.style.setProperty(
      pamphletSectionProgressVar,
      String(currentSectionProgress)
    );

    onProgressChange(currentSectionProgress, sectionIndex);
    setCurrentSection(sectionIndex);
  }, [
    containerHeightVh,
    onProgressChange,
    outerContainer,
    props.sectionHeights,
    stickyDisplayContainer,
  ]);

  // Reset the progress when the page is loaded off.
  useEffect(() => {
    return () => {
      onProgressChange(0, 0);
    };
  }, [onProgressChange]);

  // Log position of where the user is.
  useEffect(() => {
    logEvent({
      subscribedEventType: SubscribedEventType.USER_INTERACTION,
      eventAction: 'Scroll',
      eventCategory: 'CheeriosBlock',
      eventLabel: `Section ${currentSection}`,
    });
  }, [currentSection]);

  useEffect(() => {
    const passiveListenerOptions: AddEventListenerOptions = { passive: true };
    window.addEventListener('scroll', updateProgress, passiveListenerOptions);

    return () => {
      window.removeEventListener('scroll', updateProgress, passiveListenerOptions);
    };
  }, [updateProgress]);

  const outerContainerCssVars = {
    // Using 100vh because it doesn't change with url bars showing up on mobile.
    // This stops content from jumping around.
    [pamphletSectionContainerHeight]: `calc(calc(100vh - var(${totalHeaderHeightCssVar})) * ${containerHeightVh})`,
    [pamphletOffsetTopVar]: `var(${totalHeaderHeightCssVar})`,
  } as CSSProperties;

  const scrollContainerCssVars = {
    [pamphletSectionProgressVar]: 0,
  } as CSSProperties;

  return (
    <article
      ref={outerContainerCapture}
      style={outerContainerCssVars}
      className={outerContainerCss}
      id="pamphlet"
    >
      <section
        ref={stickyDisplayContainerCapture}
        style={scrollContainerCssVars}
        className={stickyDisplayContainerCss}
        id="pamphlet-display"
      >
        {props.children}
      </section>
    </article>
  );
};
