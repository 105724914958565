import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment CheeriosMultiVideoBlockAll on CheeriosMultiVideoBlock {
      ...ContentfulSysId
      multiVideoBlock {
        ...ContentfulSysId
      }
      mainTextCollection(limit: 10) {
        items {
          text
        }
      }
      secondaryTextCollection(limit: 10) {
        items {
          text
        }
      }
      togglePanelLabel
    }
    ${contentfulSysIdFragment}
  `,
};
