import { css } from '@emotion/css';
import { mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

import { mediaQuery } from '../../../../styles/mediaQueries';

export const videoTextCss = css`
  position: absolute;
  width: 100%;
  height: 100%;
  display: inherit;
`;

export const mainVideoTextCss = css`
  font-style: italic;
  font-size: 2.5em;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%) rotate(-8deg);
  text-align: center;
  line-height: 1em;
`;

export const secondaryVideoTextCss = css`
  position: absolute;
  align-self: center;
  bottom: 140px;
  left: 50%;

  transform: translateX(-50%);
  text-align: center;

  ${mobileMediaQuery} {
    max-width: 90%;
    width: fit-content;
    font-size: 0.8em;
  }

  ${mediaQuery.over768_desktop_small} {
    width: 55%;
    max-width: 1500px;
  }

  ${mediaQuery.over2560_desktop_larger} {
    font-size: 0.6em;
  }
`;

export const gradientCss = css`
  position: absolute;
  top: 90%;
  left: 0;
  height: 10%;
  width: 100vw;
  background: linear-gradient(to bottom, transparent 0%, black 100%);
`;

export const togglePanelCss = css`
  /* These are hacks to mid-align regardless of what's in contentful */
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  font-size: 18px;
  bottom: 40px;

  ${mobileMediaQuery} {
    font-size: 12px;
    /* On mobile we need to clear IPhones button zone so the toggle panel is clickable. */
    bottom: 62px;
  }
`;
